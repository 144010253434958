<template>
  <div class="product-editor-popup-pages">
    <a-modal
        v-model="showPopup"
        :width='700'
        title="驳回"
        ok-text="确认"
        cancel-text="取消"
        @cancel="handleCancelReject"
        @ok="handleConfirmReject"
    >
      <a-select
          v-if="!showSelectRefuseAuto"
          allowClear
          v-model="productRefuseType"
          class="w-600"
          placeholder='请选择驳回原因'
          @change="handleChangeSelectRefuseType"
      >
        <a-select-option
            v-for="item in productRejectTypeList"
            :key="item.id"
            :value='item.id'
        >
          <span>{{ item.value }}</span>
          <a-icon v-if="item.icon" :type="item.icon" class="color-blue ml-5"/>
        </a-select-option>
      </a-select>
      <div v-else style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
        <a-input
            allowClear
            v-model="showSelectRefuseText"
            class="w-400"
            placeholder='输入反驳原因'
        ></a-input>
        <a-tooltip placement="top">
          <template slot="title">
            <span>关闭自定义输入驳回原因</span>
          </template>
          <a-icon @click="handleCloseAuto" type="close-circle" class="ml-20 font-size-24 color-red cur-pot"/>
        </a-tooltip>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {FIXED_PRICE_REJECT_TEXT_LIST} from "@/views/cmsPage/productManage/fixedPriceProductList/_data"

export default {
  emits: ['editSuccess'],
  data() {
    return {
      showPopup: false,
      showSelectRefuseAuto: false,
      productRefuseType: undefined,
      productRejectTypeList: FIXED_PRICE_REJECT_TEXT_LIST,
      showSelectRefuseText: undefined,
      selectProductId: undefined,
    }
  },
  methods: {
    /** 展示驳回弹窗 */
    show(data) {
      this.selectProductId = data.id
      this.showPopup = true
    },
    /** 确定驳回 */
    async handleConfirmReject() {
      let found
      if (!this.showSelectRefuseAuto) {
        if (!this.productRefuseType) {
          return this.$message.warn('请选择驳回原因')
        }
        found = this.productRejectTypeList.find(el => el.id === this.productRefuseType).value
      } else {
        if (!this.showSelectRefuseText) {
          return this.$message.warn('请输入驳回原因')
        }
        found = this.showSelectRefuseText
      }
      await this.$parent.reviewHandle(this.selectProductId, -1, found)
    },
    /** 改变选择的驳回原因 */
    handleChangeSelectRefuseType(e) {
      if (e === 'auto') {
        this.showSelectRefuseAuto = true
      }
    },
    /** 取消驳回 */
    handleCancelReject() {
      this.showSelectRefuseAuto = false
      this.productRefuseType = undefined
      this.showSelectRefuseText = undefined
      this.selectProductId = undefined
      this.showPopup = false
    },
    /** 关闭自定输入 */
    handleCloseAuto() {
      this.productRefuseType = undefined
      this.showSelectRefuseAuto = false
    },
  }
}
</script>
<style lang="scss" scoped>

</style>