var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "编辑宝贝",
        width: 750,
        "ok-text": "确认",
        "cancel-text": "取消"
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "popup-content" },
        [
          _c(
            "a-form-model",
            _vm._b({}, "a-form-model", _vm.layout, false),
            [
              _c(
                "a-form-item",
                { attrs: { label: "宝贝名称" } },
                [
                  _c("a-input", {
                    staticClass: "w-400",
                    attrs: { allowClear: "", placeholder: "宝贝名称" },
                    model: {
                      value: _vm.params.productName,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "productName", $$v)
                      },
                      expression: "params.productName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "大币种" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-400",
                      attrs: {
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请选择"
                      },
                      on: { search: _vm.coinSearch },
                      model: {
                        value: _vm.params.coinSid,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "coinSid", $$v)
                        },
                        expression: "params.coinSid"
                      }
                    },
                    _vm._l(_vm.bigCoinList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.sid } },
                        [
                          _vm._v(
                            _vm._s(
                              item.coinKindName +
                                (item.coinCategory
                                  ? "---" + item.coinCategory
                                  : "")
                            )
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "宝贝介绍" } },
                [
                  _c("a-textarea", {
                    staticClass: "w-400",
                    attrs: { placeholder: "宝贝介绍", rows: 4 },
                    model: {
                      value: _vm.params.context,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "context", $$v)
                      },
                      expression: "params.context"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "评级公司(可选裸币)" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-200",
                      attrs: { allowClear: "", placeholder: "评级公司" },
                      on: {
                        change: function($event) {
                          return _vm.handleChangeCompany($event)
                        }
                      },
                      model: {
                        value: _vm.params.productRatingCompany,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "productRatingCompany", $$v)
                        },
                        expression: "params.productRatingCompany"
                      }
                    },
                    _vm._l(_vm.ratingCompanyTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _vm.params.productRatingCompany !== 0
                    ? _c("a-input", {
                        staticClass: "ml-10 w-200",
                        attrs: { allowClear: "", placeholder: "填写评级编号" },
                        model: {
                          value: _vm.params.productCode,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "productCode", $$v)
                          },
                          expression: "params.productCode"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "宝贝尺寸" } },
                [
                  _c("a-input", {
                    staticClass: "w-400",
                    attrs: { allowClear: "", placeholder: "宝贝尺寸" },
                    model: {
                      value: _vm.params.productSize,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "productSize", $$v)
                      },
                      expression: "params.productSize"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "宝贝价格" } },
                [
                  _c("a-input", {
                    staticClass: "w-400",
                    attrs: {
                      allowClear: "",
                      type: "number",
                      placeholder: "宝贝价格"
                    },
                    model: {
                      value: _vm.params.finalPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "finalPrice", $$v)
                      },
                      expression: "params.finalPrice"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "图片" } },
                [
                  _c("UploadImage", {
                    attrs: { list: _vm.coverImgList, maxCount: 20 },
                    on: { change: _vm.handleChangeByProductImg }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }