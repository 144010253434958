import {objArrMapObj} from "@/untils/otherEvent"

/** 列表数据 */
export const tableListColumns = [
    { title: "ID", scopedSlots: { customRender: "idSlot" }, align: 'center', },
    { title: "图片", scopedSlots: { customRender: "images" }, align: 'center', width: 400 },
    { title: "裁剪封面", scopedSlots: { customRender: "cutImages" }, align: 'center' },
    { title: "标题", scopedSlots: { customRender: "description" }, align: 'center', width: 300 },
    { title: "审核", scopedSlots: { customRender: "checkProductSlot" }, align: 'center', width: 260 },
    { title: "描述", scopedSlots: { customRender: "descriptionSlot" }, align: 'center', width: 200, },
    { title: "发布时间", dataIndex: "createTime", align: 'center', width: 120 },
    { title: "状态", scopedSlots: { customRender: 'itemStatusSlot' }, align: 'center', width: 200 },
    { title: "操作", scopedSlots: { customRender: 'itemActionSlot' }, align: 'center', width: 100 },
]

/** 一口价审核状态 */
export const FIXED_PRICE_CHECK_STATUS_LIST = [
    { name: '甄选', value: 8 },
    { name: '精品', value: 1 },
    { name: '普品', value: 2 },
    { name: '差品', value: 3 },
    { name: '待审核', value: 0 },
    { name: '已驳回', value: -1 },
]
/** 一口价审核状态样式类 */
export const FIXED_PRICE_CHECK_STATUS_CLASS_TYPE = {
    0: 'color-gray',
    1: 'color-blue',
    2: 'color-black',
    3: 'color-gray',
    8: 'color-orange',
    '-1': 'color-red',
}

export const computedFixedPriceCheckStatusListMapText = (value) => {
    return objArrMapObj('value', 'name', FIXED_PRICE_CHECK_STATUS_LIST)[value]
}

/** 驳回原因列表--一口价 */
export const FIXED_PRICE_REJECT_TEXT_LIST = [
    { id: 1, value: "法律法规禁止", },
    { id: 2, value: "图片原因(不清晰/展示不完整/遮挡/带其他平台水印等)",  },
    { id: 3, value: "评级信息无法查询", },
    { id: 4, value: "图文及描述明显冲突或错误,或明显误导容易引起歧义",  },
    { id: 5, value: "平台暂不支持",  },
    { id: 6, value: "明显存疑或赝品",  },
    { id: 'auto', value: '输入其他自定义原因', icon: 'edit'}
]

/** 审核操作按钮 */
export const PRODUCT_CHECK_ACTION_BTN_TYPE_LIST = [
    { name: '甄选', btnType: 'danger', value: 8, rejectPopupShow: false },
    { name: '精品', btnType: 'primary', value: 1, rejectPopupShow: true },
    { name: '普品', btnType: 'default', value: 2, rejectPopupShow: true },
    { name: '差品', btnType: 'danger', value: 3, rejectPopupShow: false },
]

/** 编辑，驳回操作按钮 */
export const PRODUCT_EDIT_OR_REJECT_ACTION_BTN_TYPE_LIST = [
    { name: '驳回', btnType: 'danger', value: 'reject' },
    { name: '编辑', btnType: 'primary', value: 'edit' },
]