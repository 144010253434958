<template>
  <a-modal
      v-model="showPopup"
      title="修改产品描述"
      :width="600"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="">
          <a-textarea
              v-model="params.remarkText"
              placeholder="修改产品描述"
              :rows="4"
          />
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {postChangeFixedPriceProductRemark} from "@/views/cmsPage/productManage/fixedPriceProductList/_apis"

export default {
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        id: undefined,
        remarkText: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.params.remarkText = data.context
      this.params.id = data.id
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        remarkText: undefined,
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.remarkText) return this.$message.warn("请填写描述")
      this.$loading.show()
      const res = await postChangeFixedPriceProductRemark({
        id: this.params.id,
        context: this.params.remarkText,
      })
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('编辑成功')
      this.handleCancel()
      this.$emit('editorSuccess')
    },
  }
}
</script>