import instance from "@/axios"

/** 编辑产品信息 */
export const postChangeFixedProductProductInfo = async (params) => {
    return instance({
        url: '/dq_admin/product/edit',
        method: 'POST',
        data: params
    })
}

/** 编辑产品描述 */
export const postChangeFixedPriceProductRemark = async (params) => {
    return instance({
        url: '/dq_admin/product/editAuctionProduct',
        method: 'POST',
        data: params
    })
}