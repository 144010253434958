var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-editor-popup-pages" },
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 700,
            title: "驳回",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.handleCancelReject, ok: _vm.handleConfirmReject },
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          !_vm.showSelectRefuseAuto
            ? _c(
                "a-select",
                {
                  staticClass: "w-600",
                  attrs: { allowClear: "", placeholder: "请选择驳回原因" },
                  on: { change: _vm.handleChangeSelectRefuseType },
                  model: {
                    value: _vm.productRefuseType,
                    callback: function($$v) {
                      _vm.productRefuseType = $$v
                    },
                    expression: "productRefuseType"
                  }
                },
                _vm._l(_vm.productRejectTypeList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [
                      _c("span", [_vm._v(_vm._s(item.value))]),
                      item.icon
                        ? _c("a-icon", {
                            staticClass: "color-blue ml-5",
                            attrs: { type: item.icon }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "center"
                  }
                },
                [
                  _c("a-input", {
                    staticClass: "w-400",
                    attrs: { allowClear: "", placeholder: "输入反驳原因" },
                    model: {
                      value: _vm.showSelectRefuseText,
                      callback: function($$v) {
                        _vm.showSelectRefuseText = $$v
                      },
                      expression: "showSelectRefuseText"
                    }
                  }),
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("关闭自定义输入驳回原因")])
                      ]),
                      _c("a-icon", {
                        staticClass: "ml-20 font-size-24 color-red cur-pot",
                        attrs: { type: "close-circle" },
                        on: { click: _vm.handleCloseAuto }
                      })
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }