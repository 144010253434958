<template>
  <a-modal
      v-model="showPopup"
      title="编辑宝贝"
      :width="750"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model v-bind="layout">
        <a-form-item label="宝贝名称">
          <a-input
              allowClear
              v-model="params.productName"
              class="w-400"
              placeholder='宝贝名称'
          ></a-input>
        </a-form-item>
        <a-form-item label="大币种">
          <a-select
              @search='coinSearch'
              v-model="params.coinSid"
              :filter-option="untils.filterOption"
              showSearch class="w-400"
              placeholder='请选择'
          >
            <a-select-option
                v-for="item of bigCoinList"
                :key="item.id"
                :value='item.sid'
            >{{item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' )}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="宝贝介绍">
          <a-textarea
              v-model="params.context"
              placeholder="宝贝介绍"
              class="w-400"
              :rows="4"
          />
        </a-form-item>
        <a-form-item label="评级公司(可选裸币)">
          <a-select
              allowClear
              v-model="params.productRatingCompany"
              class="w-200"
              placeholder='评级公司'
              @change="handleChangeCompany($event)"
          >
            <a-select-option
                v-for="item in ratingCompanyTypeList"
                :key="item.value"
                :value='item.value'
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-input
              v-if="params.productRatingCompany !== 0"
              allowClear
              v-model="params.productCode"
              class="ml-10 w-200"
              placeholder='填写评级编号'
          ></a-input>
        </a-form-item>
        <a-form-item label="宝贝尺寸">
          <a-input
              allowClear
              v-model="params.productSize"
              class="w-400"
              placeholder='宝贝尺寸'
          ></a-input>
        </a-form-item>
        <a-form-item label="宝贝价格">
          <a-input
              allowClear
              v-model="params.finalPrice"
              class="w-400" type="number"
              placeholder='宝贝价格'
          ></a-input>
        </a-form-item>
        <a-form-item label="图片">
          <UploadImage
              :list="coverImgList"
              :maxCount="20"
              @change="handleChangeByProductImg"
          ></UploadImage>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {
  postChangeFixedProductProductInfo
} from "@/views/cmsPage/productManage/fixedPriceProductList/_apis"
import UploadImage from "@/components/UploadImage/index.vue"
import untils from "@/untils"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import {returnAntUploadImgObj, updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {RATING_COMPANY_TYPE_LIST_BY_NEW} from "@/_data"
export default {
  components: { UploadImage },
  mixins: [_bigOrSmallCoinByVersion],
  data() {
    return {
      untils,
      bigCoinList: [],
      showPopup: false,
      layout: {
        labelCol: { span: 5 }, //标签占5份  共24份
        wrapperCol: { span: 19 },
      },
      coverImgList: [],
      params: {
        id: undefined,
        productNo: undefined,
        productName: undefined,
        productSize: undefined,
        context: undefined,
        coverUrls: undefined,
        finalPrice: undefined,
        coinSid: undefined,
        productCode: undefined,
        productRatingCompany: undefined,
      },
      ratingCompanyTypeList: RATING_COMPANY_TYPE_LIST_BY_NEW,
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      data.productRatingCompany = Number(data.productRatingCompany)
      this.coverImgList = returnAntUploadImgObj(data.coverUrls)
      this.params = updateBaseObjectWithComplex(this.params, data)
      if (data.coinKindName) {
        this.bigCoinList = [{ id: 0, sid: data.coinSid, coinKindName: data.coinKindName }]
      }
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        productNo: undefined,
        productName: undefined,
        productSize: undefined,
        context: undefined,
        coverUrls: undefined,
        finalPrice: undefined,
        coinSid: undefined,
        productCode: undefined,
        productRatingCompany: undefined,
      }
      this.bigCoinList = []
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      this.$loading.show()
      if (!this.coverImgList.length) return this.$message.warning('图片必须上传')
      this.params.coverUrls = this.coverImgList.map(el => el.url)
      const res = await postChangeFixedProductProductInfo(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('编辑成功')
      this.handleCancel()
      this.$emit('editorSuccess')
    },
    /** 修改评级公司 */
    handleChangeCompany(e) {
      if (!e) this.params.productCode = undefined
    },
    /** 改变一口价图片 */
    handleChangeByProductImg(list) {
      this.coverImgList = list
    }
  }
}
</script>