var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "标题or商品Id"
              },
              model: {
                value: _vm.searchParams.productName,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "productName", $$v)
                },
                expression: "searchParams.productName"
              }
            }),
            _c("a-input", {
              staticClass: "w-200 ml-5 mb-5",
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "发布者OrUserId"
              },
              model: {
                value: _vm.searchParams.userName,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "userName", $$v)
                },
                expression: "searchParams.userName"
              }
            }),
            _c(
              "a-select",
              {
                staticClass: "w-200 ml-5 mb-5",
                attrs: {
                  allowClear: "",
                  autocomplete: "off",
                  "filter-option": _vm.untils.filterOption,
                  showSearch: "",
                  size: "small",
                  placeholder: "查询审核人"
                },
                on: { search: _vm.searchUser },
                model: {
                  value: _vm.searchParams.reviewAdminId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "reviewAdminId", $$v)
                  },
                  expression: "searchParams.reviewAdminId"
                }
              },
              _vm._l(_vm.userDataList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.userName + "【" + item.id + "】"))]
                )
              }),
              1
            ),
            _c(
              "a-select",
              {
                staticClass: "w-100 ml-5 mb-5",
                attrs: {
                  allowClear: "",
                  autocomplete: "off",
                  size: "small",
                  placeholder: "上下架"
                },
                model: {
                  value: _vm.searchParams.onShelf,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "onShelf", $$v)
                  },
                  expression: "searchParams.onShelf"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 1 } }, [
                  _vm._v("上架")
                ]),
                _c("a-select-option", { attrs: { value: 0 } }, [_vm._v("下架")])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticClass: "ml-5 mb-5 w-150",
                attrs: { allowClear: "", size: "small", placeholder: "审核" },
                model: {
                  value: _vm.searchParams.reviewStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "reviewStatus", $$v)
                  },
                  expression: "searchParams.reviewStatus"
                }
              },
              _vm._l(_vm.fixedPriceCheckStatusList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.value, attrs: { value: item.value } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
            _c("a-input", {
              staticClass: "ml-5 mb-5 w-150",
              attrs: { allowClear: "", size: "small", placeholder: "评级编号" },
              model: {
                value: _vm.searchParams.productCode,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "productCode", $$v)
                },
                expression: "searchParams.productCode"
              }
            }),
            _c(
              "a-select",
              {
                staticClass: "ml-5 mb-5 w-200",
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "评级公司"
                },
                model: {
                  value: _vm.searchParams.productRatingCompany,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "productRatingCompany", $$v)
                  },
                  expression: "searchParams.productRatingCompany"
                }
              },
              _vm._l(_vm.ratingCompanyTypeList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.value, attrs: { value: item.value } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
            _c("a-range-picker", {
              staticStyle: { width: "200px", margin: "5px" },
              attrs: { placeholder: ["开始日期", "结束日期"], size: "small" },
              on: { change: _vm.searchPickerChange }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "大币种" },
              model: {
                value: _vm.searchParams.coinKindName,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "coinKindName", $$v)
                },
                expression: "searchParams.coinKindName"
              }
            }),
            _c(
              "a-select",
              {
                staticClass: "w-200 ml-5 mb-5",
                attrs: {
                  allowClear: "",
                  autocomplete: "off",
                  size: "small",
                  placeholder: "大币种分类"
                },
                model: {
                  value: _vm.searchParams.coinCategoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "coinCategoryId", $$v)
                  },
                  expression: "searchParams.coinCategoryId"
                }
              },
              _vm._l(_vm.bigCoinCategoryList, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.id, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.coinCategoryName))]
                )
              }),
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "idSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticStyle: { "max-width": "150px" } },
                      [
                        _c(
                          "a-popover",
                          { attrs: { title: "商品编号", trigger: "hover" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c("p", [_vm._v(_vm._s(row.productNo))])
                            ]),
                            _c(
                              "a",
                              {
                                staticStyle: { color: "blue" },
                                style: { color: row.deleteFlag ? "gray" : "" },
                                attrs: { type: "link" },
                                on: {
                                  click: function($event) {
                                    return _vm.copy(row.productNo)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.id))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "mt-10 color-blue",
                                on: {
                                  click: function($event) {
                                    return _vm.userIdSearch(row.userId)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color: row.deleteFlag ? "gray" : ""
                                    }
                                  },
                                  [_vm._v(_vm._s(row.userName))]
                                ),
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color: row.deleteFlag ? "gray" : ""
                                    }
                                  },
                                  [_vm._v("【" + _vm._s(row.userId) + "】")]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                }
              },
              {
                key: "images",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "images-box" },
                      _vm._l(row.coverUrls, function(item, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "lazy-container",
                                rawName: "v-lazy-container",
                                value: { selector: "img" },
                                expression: "{ selector: 'img' }"
                              }
                            ],
                            key: index
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { "data-src": item + "/resize,w_200" },
                              on: {
                                click: function($event) {
                                  return _vm.handlePreviewImg(
                                    row.coverUrls,
                                    index
                                  )
                                }
                              }
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ])
                }
              },
              {
                key: "cutImages",
                fn: function(row) {
                  return _c("div", {}, [
                    row.cutCoverUrl
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "max-width": "100px",
                              "max-height": "100px",
                              display: "flex",
                              "flex-direction": "column",
                              "justify-content": "center",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                "max-width": "100px",
                                "max-height": "100px",
                                "margin-bottom": "6px"
                              },
                              attrs: { src: row.cutCoverUrl || "" },
                              on: {
                                click: function($event) {
                                  return _vm.previewImg([row.cutCoverUrl])
                                }
                              }
                            }),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "取消裁剪封面,用第一张图作为封面",
                                  "ok-text": "Yes",
                                  "cancel-text": "No",
                                  disabled: row.deleteFlag
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.deleteImage(row)
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: {
                                      size: "small",
                                      disabled: row.deleteFlag
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }
              },
              {
                key: "description",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass: "font-weight-bold color-black",
                          style: {
                            color: row.deleteFlag ? "gray !important" : ""
                          }
                        },
                        [_vm._v(_vm._s(row.productName))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-bold color-black",
                          style: {
                            color: row.deleteFlag ? "gray !important" : ""
                          }
                        },
                        [
                          _vm._v(" 『产品描述』"),
                          _c("span", { staticClass: "color-gray" }, [
                            _vm._v(_vm._s(row.context))
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    size: "small",
                                    disabled: row.deleteFlag,
                                    type: "danger"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleChangeEditorProductRemark(
                                        row
                                      )
                                    }
                                  }
                                },
                                [_vm._v("修改产品描述")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-bold font-size-16 color-blue mt-10",
                          style: {
                            color: row.deleteFlag ? "gray !important" : ""
                          }
                        },
                        [_vm._v("尺寸：" + _vm._s(row.productSize))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-weight-bold font-size-16 color-red mt-10",
                          style: {
                            color: row.deleteFlag ? "gray !important" : ""
                          }
                        },
                        [_vm._v("￥" + _vm._s(row.finalPrice))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "font-weight-bold mt-10",
                          class:
                            _vm.fixedPriceCheckStatusClassType[row.reviewStatus]
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color: row.deleteFlag ? "gray !important" : ""
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.computedFixedPriceCheckStatusText(
                                    row.reviewStatus
                                  )
                                )
                              )
                            ]
                          ),
                          row.reviewStatus === -1
                            ? _c(
                                "span",
                                {
                                  staticClass: "color-red",
                                  style: {
                                    color: row.deleteFlag
                                      ? "gray !important"
                                      : ""
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      "（" +
                                        (row.reviewDetail
                                          ? row.reviewDetail.reviewReason
                                          : "") +
                                        "）"
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      row.reviewDetail
                        ? _c(
                            "p",
                            {
                              staticClass: "font-weight-bold color-black mt-10",
                              style: {
                                color: row.deleteFlag ? "gray !important" : ""
                              }
                            },
                            [
                              _vm._v(" 『审核人』"),
                              _c("span", { staticClass: "color-gray" }, [
                                _vm._v(_vm._s(row.reviewDetail.reviewAdmin))
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                }
              },
              {
                key: "descriptionSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", { staticClass: "w-100-w" }, [
                      _c(
                        "div",
                        {
                          style: {
                            color: !row.deleteFlag ? "gray !important" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.copy(
                                row.productRemarkVo.coinStateRemark
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 平台定义瑕疵：" +
                              _vm._s(
                                row.productRemarkVo.coinStateRemark || " "
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: {
                            color: !row.deleteFlag ? "gray !important" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.copy(row.productRemarkVo.customRemark)
                            }
                          }
                        },
                        [
                          _vm._v(" 用户自定义瑕疵： "),
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(row.productRemarkVo.customRemark))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: {
                            color: !row.deleteFlag ? "gray !important" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.copy(
                                row.productRemarkVo.platformRemark
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 平台点评：" +
                              _vm._s(
                                row.productRemarkVo.platformRemark || " "
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: { color: row.deleteFlag ? "gray" : "orange" },
                          on: {
                            click: function($event) {
                              return _vm.copy(
                                row.productRemarkVo.ratingFlawCode
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 评级编码：" +
                              _vm._s(
                                row.productRemarkVo.ratingFlawCode || " "
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          style: { color: row.deleteFlag ? "gray" : "orange" },
                          on: {
                            click: function($event) {
                              return _vm.copy(row.productRemarkVo.ratingRemark)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " 评级备注：" +
                              _vm._s(row.productRemarkVo.ratingRemark || " ") +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: {
                                size: "small",
                                disabled: row.deleteFlag
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleEditorProductRemark(row)
                                }
                              }
                            },
                            [_vm._v("编辑产品瑕疵")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                }
              },
              {
                key: "itemActionSlot",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.onShelf === 0
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认上架吗?",
                                "ok-text": "Yes",
                                "cancel-text": "No",
                                disabled: row.deleteFlag
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleRacking(row, 1)
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: {
                                    disabled: row.deleteFlag,
                                    type: "primary",
                                    size: "small"
                                  }
                                },
                                [_vm._v("上架")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      row.onShelf === 1
                        ? _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认下架吗?",
                                "ok-text": "Yes",
                                "cancel-text": "No",
                                disabled: row.deleteFlag
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleRacking(row, 0)
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: {
                                    type: "danger",
                                    disabled: row.deleteFlag,
                                    size: "small"
                                  }
                                },
                                [_vm._v("下架")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "checkProductSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    row.coinKindName
                      ? _c(
                          "div",
                          {
                            staticClass: "font-weight-bold color-black",
                            style: {
                              color: !row.deleteFlag ? "gray !important" : ""
                            }
                          },
                          [_vm._v(_vm._s(row.coinKindName))]
                        )
                      : _vm._e(),
                    row.productCode
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "flex-center-center font-weight-bold mt-10",
                            style: {
                              color: !row.deleteFlag ? "gray !important" : ""
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "color-blue",
                                style: {
                                  color: !row.deleteFlag
                                    ? "gray !important"
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.computedShowTextComputedRatingCompanyTypeList(
                                      row.productRatingCompany
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" 【"),
                            _c(
                              "div",
                              {
                                staticClass: "color-blue",
                                style: {
                                  color: !row.deleteFlag
                                    ? "gray !important"
                                    : ""
                                }
                              },
                              [_vm._v(_vm._s(row.productCode))]
                            ),
                            _vm._v("】 ")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "flex-center-center flex-wrap mt-10" },
                      _vm._l(
                        _vm.fixedPriceProductCheckActionBtnTypeList,
                        function(item) {
                          return _c(
                            "a-popconfirm",
                            {
                              key: item.value,
                              attrs: {
                                title: "确定审核为" + item.name + "吗？",
                                "ok-text": "Yes",
                                "cancel-text": "No",
                                disabled: row.deleteFlag
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.reviewHandle(
                                    row.id,
                                    item.value,
                                    ""
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "mr-5",
                                  attrs: {
                                    disabled: row.deleteFlag,
                                    type: item.btnType,
                                    size: "small"
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-center-center flex-wrap mt-10" },
                      _vm._l(
                        _vm.fixedPriceProductEditOrRejectActionBtnTypeList,
                        function(item) {
                          return _c(
                            "a-button",
                            {
                              key: item.value,
                              staticClass: "mr-5",
                              attrs: {
                                type: item.btnType,
                                size: "small",
                                disabled: row.deleteFlag
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleEditOrRejectBtnShow(
                                    item,
                                    row
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }
                      ),
                      1
                    )
                  ])
                }
              },
              {
                key: "itemStatusSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    row.onShelf
                      ? _c(
                          "span",
                          {
                            staticStyle: { color: "green" },
                            style: {
                              color: !row.deleteFlag ? "gray !important" : ""
                            }
                          },
                          [_vm._v(_vm._s(row.statusName))]
                        )
                      : _c(
                          "span",
                          {
                            staticStyle: { color: "red" },
                            style: {
                              color: !row.deleteFlag ? "gray !important" : ""
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.reviewStatus === -1
                                  ? "已驳回（" +
                                      (row.reviewDetail
                                        ? row.reviewDetail.reviewReason
                                        : "") +
                                      "）"
                                  : row.statusName
                              )
                            )
                          ]
                        )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c("EditorProductRemarkPopup", {
        ref: "editorProductRemarkPopupEl",
        attrs: { "editor-type": "fixed" },
        on: {
          editorSuccess: function($event) {
            return _vm.handleEditorSuccess($event)
          }
        }
      }),
      _c("EditorProductRemark", {
        ref: "editorProductRemarkEl",
        on: {
          editorSuccess: function($event) {
            return _vm.handleEditorSuccess($event)
          }
        }
      }),
      _c("FixedPriceProductRejectPopup", {
        ref: "fixedPriceProductRejectPopupEl"
      }),
      _c("FixedPriceEditorProductPopup", {
        ref: "fixedPriceEditorProductPopupEl",
        on: {
          editorSuccess: function($event) {
            return _vm.handleEditorSuccess($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }